"use client";
import {
  Toaster as Sonner,
  toast as sonnerToast,
  type ExternalToast,
} from "sonner";

type ToasterProps = React.ComponentProps<typeof Sonner>;

/**
 * A customizable toast notification component
 *
 * Renders toast notifications with consistent styling and positioning
 */
function Toaster({ ...props }: ToasterProps) {
  return (
    <Sonner
      className="toaster group"
      toastOptions={{
        classNames: {
          toast:
            "group toast font-sans group-[.toaster]:bg-white group-[.toaster]:text-black group-[.toaster]:border-gray-200 group-[.toaster]:shadow-lg",
          description: "group-[.toast]:text-gray-500",
          actionButton:
            "group-[.toast]:data-[action]:bg-black group-[.toast]:data-[action]:text-white",
          cancelButton:
            "group-[.toast]:data-[cancel]:bg-red-500 group-[.toast]:data-[cancel]:text-white",
        },
      }}
      {...props}
    />
  );
}

type ToastOptions = ExternalToast & {
  /** Additional descriptive text shown below the main message */
  description?: string;

  /** Time in milliseconds that should elapse before automatically closing the toast.
   *
   * @default 4000
   */
  duration?: number;

  /** If `false`, it'll prevent the user from dismissing the toast.
   *
   * @default true
   */
  dismissible?: boolean;

  /** Icon displayed in front of toast's text, aligned vertically.*/
  icon?: React.ReactNode;

  /** Unique identifier for the toast. Used for dismissing specific toasts */
  id?: string | number;

  /**
   * The function gets called when either the close button is clicked, or the toast is swiped.
   *
   * @example
   * toast('Message', {
   *   onDismiss: () => handleDismiss()
   * })
   */
  onDismiss?: () => void;

  /**
   * Function that gets called when the toast disappears automatically after its timeout (duration prop).
   *
   * @example
   * toast('Message', {
   *   onAutoClose: () => handleAutoClose()
   * })
   */
  onAutoClose?: () => void;

  /**
   * Configuration for the primary action button
   *
   * Clicking button will dismiss the toast
   *
   * @example
   * toast('Message', {
   *   action: {
   *     label: 'Undo',
   *     onClick: () => handleUndo()
   *   }
   * })
   */
  action?: {
    /** Text to display on the action button */
    label: string;
    /** Callback function when action button is clicked */
    onClick: () => void;
  };

  /**
   * Configuration for the cancel button
   *
   * Clicking button will dismiss the toast
   *
   * @example
   * toast('Message', {
   *   cancel: {
   *     label: 'Cancel',
   *     onClick: () => handleCancel()
   *   }
   * })
   */
  cancel?: {
    /** Text to display on the cancel button */
    label: string;
    /** Callback function when cancel button is clicked */
    onClick: () => void;
  };
};

/**
 * Display a toast notification
 * @param message The message to display
 * @param options Configuration options for the toast
 * @returns A unique ID (string | number) that can be used to dismiss the toast
 * @description
 * The toast function can be used directly or through its variants:
 * - toast() - default toast
 * - toast.success() - toast with checkmark
 * - toast.error() - toast with error icon
 * - toast.warning() - toast with a warning icon
 * - toast.info() - toast with an info icon
 * - toast.loading() - toast with a loading spinner
 * - toast.dismiss() - removes a specific toast by ID
 * - toast.promise() - handles async operations with loading/success/error states
 */
const toast: {
  (message: string, options?: ToastOptions): string | number;
  /** Display a toast with a success icon */
  success: (message: string, options?: ToastOptions) => string | number;
  /** Display a toast with an info icon */
  info: (message: string, options?: ToastOptions) => string | number;
  /** Display a toast with a warning icon */
  warning: (message: string, options?: ToastOptions) => string | number;
  /** Display a toast with an error icon */
  error: (message: string, options?: ToastOptions) => string | number;
  /** Display a toast with a loading spinner */
  loading: (message: string, options?: ToastOptions) => string | number;
  /**
   * Dismiss/remove a specific toast by its ID
   *
   * @param toastId The ID of the toast to dismiss
   */
  dismiss: (toastId: string | number) => void;
  /**
   * Display a loading toast that automatically updates when the promise resolves or rejects
   * @example
   * toast.promise(fetchData(), {
   *   loading: 'Fetching data...',
   *   success: (data) => `Successfully fetched ${data.length} items`,
   *   error: (err) => `Error: ${err.message}`
   * });
   */
  promise: typeof sonnerToast.promise;
} = sonnerToast;

export { Toaster, toast, type ToastOptions };
