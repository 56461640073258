import Image from "next/image";
import Link from "next/link";
import { Button } from "~/ui/button";
import { Icon } from "~/ui/icon";
import nextErrorIcon from "./next-error.svg";

export enum AppErrorMode {
  NotFound = "not-found",
  Error = "error",
}
export type AppErrorProps = {
  mode?: AppErrorMode;
  message?: string;
  title?: string;
};

const MODE_TITLES = {
  [AppErrorMode.NotFound]: "You might be lost!",
  [AppErrorMode.Error]: "Something went wrong!",
};

const MODE_MESSAGES = {
  [AppErrorMode.NotFound]:
    "Not what you're looking for... Don't worry - you have our support!",
  [AppErrorMode.Error]: "Please try again later.",
};

// TODO(DEV-330) extract svg into our own icon library rather than hardcoding
export const NextAppError = (props: AppErrorProps) => {
  const { mode = AppErrorMode.NotFound } = props;

  const title = props.title || MODE_TITLES[mode];
  const message = props.message || MODE_MESSAGES[mode];

  return (
    <div className="flex w-full flex-auto flex-col items-center justify-center gap-4">
      <div
        className="text-center text-5xl font-bold"
        data-testid="app-error-title"
      >
        {title}
      </div>
      <div
        className="text-center text-gray-800"
        data-testid="app-error-message"
        dangerouslySetInnerHTML={{ __html: message }}
      />
      <Image src={nextErrorIcon} alt={"Application Error"} />
      {mode === AppErrorMode.NotFound && (
        <Link href="/" prefetch={false}>
          <button className="inline-flex items-center justify-center rounded-md border border-transparent/0 bg-blue-500 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm ring-offset-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
            <Icon
              icon="faIntellproCustom"
              size="lg"
              data-testid="search-results__loading"
            />
            <div className="pl-2">To Home</div>
          </button>
        </Link>
      )}
      <div className="flex flex-col items-center pt-8">
        If you keep ending up here, it may be necessary to logout and log back
        in.
        <Button
          className="mt-4 w-fit"
          href="/auth/logout"
          prefetch={false}
          size={"lg"}
          variant={"default"}
        >
          Logout
        </Button>
      </div>
    </div>
  );
};
