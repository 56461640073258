import * as brandIcons from "@awesome.me/kit-6357606804/icons/classic/brands";
import * as lightIcons from "@awesome.me/kit-6357606804/icons/classic/light";
import * as regularIcons from "@awesome.me/kit-6357606804/icons/classic/regular";
import * as solidIcons from "@awesome.me/kit-6357606804/icons/classic/solid";
import * as customIcons from "@awesome.me/kit-6357606804/icons/kit/custom";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";

/**
 * Type system for Font Awesome icons that:
 * 1. Combines all icon sets (light, regular, solid, brand, custom)
 * 2. Appends variant names to icons (e.g., faCommentRegular, faBarsLight)
 * 3. Provides type safety and autocomplete for all icons and their variants
 */

type DerivedIconName<
  T extends Record<string, IconDefinition>,
  V extends string,
> = `${Extract<keyof T, string>}${Capitalize<V>}`;

type LightIconName = DerivedIconName<typeof lightIcons, "Light">;
type RegularIconName = DerivedIconName<typeof regularIcons, "Regular">;
type SolidIconName = DerivedIconName<typeof solidIcons, "Solid">;
type CustomIconName = DerivedIconName<typeof customIcons, "Custom">;
type BrandIconName = DerivedIconName<typeof brandIcons, "Brand">;

export type IconName =
  | LightIconName
  | RegularIconName
  | SolidIconName
  | CustomIconName
  | BrandIconName;

function createIconMap(): Record<IconName, IconDefinition> {
  const mappedIcons: Record<string, IconDefinition> = {};

  const addIcon =
    <V extends string>(variant: V) =>
    ([key, icon]: [string, IconDefinition]) => {
      mappedIcons[`${key}${variant}`] = icon;
    };

  Object.entries(lightIcons).forEach(addIcon("Light"));
  Object.entries(regularIcons).forEach(addIcon("Regular"));
  Object.entries(solidIcons).forEach(addIcon("Solid"));
  Object.entries(customIcons).forEach(addIcon("Custom"));
  Object.entries(brandIcons).forEach(addIcon("Brand"));

  return mappedIcons;
}

const iconMap = createIconMap();

type IconProps = Omit<FontAwesomeIconProps, "icon"> & {
  /**
   * Name of the icon to display
   *
   * Format: "faIconNameVariant" (e.g., "faCommentRegular", "faBarsLight")
   */
  icon: IconName;
  className?: string;
};

/**
 * Type-safe wrapper for FontAwesomeIcon component
 *
 * Supports all icons from the IntellPro Font Awesome Kit
 */
export function Icon({ className, icon, ...props }: IconProps) {
  return (
    <FontAwesomeIcon
      data-slot="icon"
      className={className}
      icon={iconMap[icon]}
      {...props}
    />
  );
}
