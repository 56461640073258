import { cva, type VariantProps } from "class-variance-authority";
import Link from "next/link";
import { twJoin } from "tailwind-merge";

const buttonVariants = cva(
  "inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium transition-colors data-[slot=icon]:pointer-events-none data-[slot=icon]:size-4 data-[slot=icon]:shrink-0 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-blue-500 text-white hover:bg-blue-700",
        danger: "bg-red-500 text-white hover:bg-red-700",
        warning: "bg-yellow-500 text-white hover:bg-yellow-700",
        outline:
          "border border-gray-200 bg-white hover:bg-gray-100 hover:text-gray-900",
        secondary: "bg-gray-200 text-gray-900 hover:bg-gray-300",
        ghost: "hover:bg-gray-100 hover:text-gray-900",
      },
      size: {
        default: "h-10 px-4 py-2",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export type ButtonVariant = VariantProps<typeof buttonVariants>["variant"];
export type ButtonSize = VariantProps<typeof buttonVariants>["size"];

type NativeButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

// Union type combining either HTML button props OR Next.js Link props
// Plus our custom variant and size props
type ButtonProps = (NativeButtonProps | React.ComponentProps<typeof Link>) & {
  variant?: ButtonVariant;
  size?: ButtonSize;
};

/**
 * A button component that supports various styles and sizes.
 *
 * Extends native `<button />`.
 *
 * Provide `href` prop to extend Next.js `<Link />` instead.
 *
 * @todo validate colors with design
 */
function Button({ className, variant, size, children, ...props }: ButtonProps) {
  if ("href" in props) {
    return (
      <Link
        className={twJoin(buttonVariants({ variant, size, className }))}
        {...props}
        href={props.href}
      >
        {children}
      </Link>
    );
  }
  return (
    <button
      className={twJoin(buttonVariants({ variant, size, className }))}
      type={props.type ?? "button"}
      {...props}
    >
      {children}
    </button>
  );
}

export { Button, buttonVariants };
